import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Typography } from "@mui/material";
import Layout from "../shared-layout";
import useStores from "../../hooks/use-stores";
import AddIcon from '@mui/icons-material/Add';
import VmButton from "../shared-button";
import { DateUtils } from "../../utilities/date";
import TabPanel from "../shared-tab-panel";
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from "i18next";
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import { ApiDataListType, ApiDataResponseListLengthCheck } from "../../constants/options";
import SlideDown from "react-slidedown";

const CommonVisitTimeObject = {
  startTime: DateUtils.convertDateTimeToLocaleString(new Date()),
  endTime: DateUtils.convertDateTimeToLocaleString(new Date()),
  unitList: [],
}

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

enum ScheduleMode {
  BEFORE_SHOW_SCHEDULE = "Before Show Schedule",
  AFTER_SHOW_TIMESLOT = "After Show Time Slot",
  AFTER_SHOW_SCHEDULE = "After Show Schedule",
}

enum RecurrenceMode {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
}

const VisitAction = observer(({ targetVisit = null, onClickAction }) => {
  const { teacherStore, groupStore, academicStore, studentStore } = useStores();
  const [visitName, setVisitName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [staffId, setStaffId] = useState<string>("0");
  const [orgStudentIdList, setOrgStudentIdList] = useState<any>([]);
  const [orgStudentId, setOrgStudentId] = useState<string>("0");
  const [initialStartTime, setInitialStartTime] = useState<string>(DateUtils.convertDateTimeToLocaleString(new Date()));
  const [initialEndTime, setInitialEndTime] = useState<string>(DateUtils.convertDateTimeToLocaleString(new Date()))
  const [visitTimeList, setVisitTimeList] = useState<any>([CommonVisitTimeObject]);
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);
  const [showTimeSlot, setShowTimeSlot] = useState<ScheduleMode>(ScheduleMode.BEFORE_SHOW_SCHEDULE);
  const [confirmButton, setConfirmButton] = useState<boolean>(false);
  const [recurScheduleMode, setRecurScheduleMode] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [recurrence, setRecurrence] = useState<any>("0");
  const [repeatTimes, setRepeatTimes] = useState<string>("1");
  const [timeInterval, setTimeInterval] = useState<string>("1");
  const [excludeWeekend, setExcludeWeekend] = useState<any>(false);
  const [orgId, setOrgId] = useState<string>("");
  const [traineeshipId, setTraineeshipId] = useState<string>("0");
  const [studentProfile, setStudentProfile] = useState<any>();
  const [unitListDisplayByIndex, setUnitListDisplayByIndex] = useState<number>(0);

  const onChangeStaff = (event: SelectChangeEvent) => {
    setStaffId(event.target.value as string);
  };
  const onChangeStudent = (event: SelectChangeEvent) => {
    let id = event.target.value as string
    setOrgStudentId(id);
    setTraineeshipId("0");
    if (visitTimeList.length > 0) {
      visitTimeList.map((v: any) => v.unitList = []);
    }
    if (+id != 0) {
      studentStore.getStudentProfileById(+id)
        .then((profile: any) => setStudentProfile(profile));
    }
  };
  const onChangeTraineeship = (event: SelectChangeEvent) => {
    let id = event.target.value as string
    setTraineeshipId(id);
    if (visitTimeList.length > 0) {
      visitTimeList.map((v: any) => v.unitList = []);
    }
    if (studentProfile && +id != 0) {
      academicStore.getUnitListByQualificationId(
        studentProfile.traineeships.find(s => s.id == +id).qualification.id, 0, 0, true);
    }
  }

  const onChangeReccurence = (event: SelectChangeEvent) => {
    setRecurrence(event.target.value as string);
  };
  const onChangeExcludeWeekend = (event: SelectChangeEvent) => {
    setExcludeWeekend(event.target.value as string);
  };

  useEffect(() => {
    setConfirmButton(false);
    if (targetVisit) {
      academicStore.getUnitListByQualificationId(targetVisit.qualificationId, 0, 0, true);
      setVisitName(targetVisit.name);
      setDescription(targetVisit.description);
      // setOrgStudentIdList(targetVisit.organisationVisitStudents.map(ovs => ovs.organisationStudentId))
      setStaffId(targetVisit.conductTrainerId);
      setTraineeshipId(targetVisit.traineeshipId);
      setOrgId(targetVisit.finalLocation.id);
      setVisitTimeList([{ startTime: targetVisit.finalStartTime, endTime: targetVisit.finalEndTime, unitList: targetVisit.visitUnits.map(v => v.id) }]);
      setShowTimeSlot(ScheduleMode.AFTER_SHOW_TIMESLOT);
      setTabValue(1);
    }
  }, [targetVisit]);

  const onChangeRecurScheduleMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecurScheduleMode(event.target.checked);
  };

  const getHourDifferences = (startTime: string, endTime: string) => {
    let diff = (new Date(startTime).getTime() - new Date(endTime).getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onGenerateTimeSlot = () => {
    let tempTimeSlots = [{ startTime: initialStartTime, endTime: initialEndTime, unitList: [] }];
    if (recurScheduleMode) {
      let tempStartTime = initialStartTime;
      let tempEndTime = initialEndTime;
      let index = 1;
      while (index < +repeatTimes) {
        let sd = new Date(tempStartTime);
        let ed = new Date(tempEndTime);
        switch (+recurrence) {
          case RecurrenceMode.MONTHLY:
            sd.setMonth(sd.getUTCMonth() + (1 * +timeInterval), 1);
            ed.setMonth(ed.getUTCMonth() + (1 * +timeInterval), 1);
            // as set month will set the date to first day of next month, I'll need to find the day difference and add to it
            sd.setDate(sd.getDate() + (+initialStartTime.split('T')[0].split('-')[2] - 1));
            ed.setDate(ed.getDate() + (+initialEndTime.split('T')[0].split('-')[2] - 1));
            break;
          default:
            sd.setDate(sd.getDate() + (+recurrence == RecurrenceMode.DAILY ? (1 * +timeInterval) : (7 * +timeInterval)));
            ed.setDate(ed.getDate() + (+recurrence == RecurrenceMode.DAILY ? (1 * +timeInterval) : (7 * +timeInterval)));
            console.log(tempTimeSlots);
            break;
        }
        if (excludeWeekend) {
          if (ed.getDay() == 6) {
            sd.setDate(sd.getDate() + 2);
            ed.setDate(ed.getDate() + 2);
          } else if (ed.getDay() == 0) {
            sd.setDate(sd.getDate() + 1);
            ed.setDate(ed.getDate() + 1);
          }
        }
        tempStartTime = DateUtils.convertDateTimeToLocaleString(sd);
        tempEndTime = DateUtils.convertDateTimeToLocaleString(ed);
        tempTimeSlots.push({ startTime: tempStartTime, endTime: tempEndTime, unitList: [] });
        index++;
      }
    }
    setVisitTimeList(tempTimeSlots);
    setShowTimeSlot(ScheduleMode.AFTER_SHOW_TIMESLOT);
    setTabValue(1);
  }

  const onChangeOrgAutoComplete = (orgName: string) => {
    if (groupStore.organisationList.data.length > 0) {
      let org = groupStore.organisationList.data.find(o => orgName === o.name);
      if (org) setOrgId(org.id);
    }
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-4" style={{ marginTop: -10 }}>
        <Box>
          <TextField
            value={visitName}
            label={t('VISIT_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            placeholder={"Unit001 Weekly Visits"}
            onChange={(value) => setVisitName(value.target.value)}
            size="small"
          />
          <TextField
            value={description}
            label={t('DESCRIPTION')}
            variant="outlined"
            sx={{ width: '100%', marginY: 1.5 }}
            onChange={(value) => setDescription(value.target.value)}
            placeholder={"This visit is focusing on unit XXXX..."}
            rows={2}
            size="small"
            multiline
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            value={groupStore.organisationList.data.find(o => o.id == orgId) ? groupStore.organisationList.data.find(o => o.id == orgId).name : ""}
            options={groupStore.organisationList.data.map((o) => ({ label: o.name, id: o.id }))}
            sx={{ width: '100%', marginBottom: 1.5 }}
            onInputChange={(event, newInputValue) => onChangeOrgAutoComplete(newInputValue)}
            renderInput={(params) =>
              <TextField  {...params} label={t('Select Organisation')} />}
          />
          <FormControl fullWidth>
            <InputLabel>{t('TEACHER_SINGLE')}</InputLabel>
            <Select
              label={t('TEACHER_SINGLE')}
              sx={{ width: '100%' }}
              value={staffId}
              onChange={onChangeStaff}
              size="small"
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {
                teacherStore.teacherProfileList.data.length > 0 &&
                teacherStore.teacherProfileList.data.map((teacher: any, index: number) => (
                  <MenuItem key={`teacher_select_${index}`} value={teacher.id}>
                    {teacher.firstName + " " + teacher.lastName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {targetVisit ?
            <TextField
              value={targetVisit.qualificationName}
              label={t('DOMAIN')}
              variant="outlined"
              disabled
              sx={{ width: '100%', marginTop: 2 }}
              size="small"
            />
            : <>
              {groupStore.loadingStudentList ? <div className="p-4 text-center"><CircularProgress /></div>
                : groupStore.orgStudentList.data.length > 0 ?
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel>{t('Selected Trainee')}</InputLabel>
                    <Select
                      label={t('Selected Trainee')}
                      sx={{ width: '100%' }}
                      value={orgStudentId}
                      onChange={onChangeStudent}
                      size="small"
                    >
                      <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                      {groupStore.orgStudentList.data.map((student: any, index: number) => (
                        <MenuItem value={student.studentId}>{student.firstName + " " + student.lastName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  : <Typography py={2} variant={"body1"} textAlign="center">
                    {t('NO_STUDENTS_FOUND_PLEASE_ADD_STUDENT_ORG')}
                  </Typography>}
              {studentStore.loading ? <div className="p-4 text-center"><CircularProgress /></div>
                //  show only student is selected
                : studentProfile && +orgStudentId != 0 ?
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel>{t('Selected Student Traineeship')}</InputLabel>
                    <Select
                      label={t('Selected Student Traineeship')}
                      sx={{ width: '100%' }}
                      value={traineeshipId}
                      onChange={onChangeTraineeship}
                      size="small"
                    >
                      <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                      {studentProfile.traineeships.map((traineeship: any, index: number) => (
                        <MenuItem value={traineeship.id}>{traineeship.qualification.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  : <Typography py={2} variant={"body1"} textAlign="center">
                    {t('No Traineeship Data Found in this Trainee')}
                  </Typography>}
            </>}
        </Box>
        <Box className="col-span-2 border-l pl-4">
          <div className="flex justify-between mb-2">
            <Tabs value={tabValue} onChange={handleChange} sx={{ marginTop: -2 }}>
              <Tab label={t('SCHEDULE_TIME_SLOTS')} {...TabProps(0)} disabled={targetVisit} />
              {showTimeSlot !== ScheduleMode.BEFORE_SHOW_SCHEDULE && <Tab label={t('TIME_SLOTS')} {...TabProps(1)} />}
            </Tabs>
            {showTimeSlot !== ScheduleMode.BEFORE_SHOW_SCHEDULE && tabValue != 0 &&
              <div className="flex justify-end">
                <VmButton
                  className="bg-red-500 text-white p-2 px-3 mr-4"
                  style={{ height: 'fit-content', marginTop: -10 }}
                  disabled={targetVisit}
                  onClick={() => {
                    setVisitTimeList([]);
                    setRefreshDisplay([]);
                  }}
                >
                  {t('CLEAR')}
                </VmButton>
                <VmButton
                  className="bg-sky-500 text-white p-2 px-3"
                  style={{ height: 'fit-content', marginTop: -10 }}
                  disabled={targetVisit}
                  onClick={() => {
                    let tempTimeObject = { startTime: initialStartTime, endTime: initialEndTime };
                    visitTimeList.push(tempTimeObject);
                    setRefreshDisplay([]);
                  }}
                >
                  <AddIcon />
                </VmButton>
              </div>}
          </div>
          <div className="overflow-auto" style={{ maxHeight: 400 }}>
            {/* Tab Panel 1 */}
            <TabPanel value={tabValue} index={0} paddingValue={0}>
              <Typography variant="body1" textAlign={"center"} sx={{ textDecoration: 'underline' }}>{t('TIME_START_IN')}</Typography>
              <Box className="grid grid-cols-7 gap-2 p-4 pl-0">
                <div className="pt-3 mr-4 rounded-lg border-sky-500 text-sky-500 border-2 text-center w-full" style={{ height: 56 }}>
                  <Typography variant="h6">{DateUtils.getDayDefinition(new Date(initialStartTime)).substring(0, 2).toUpperCase()}</Typography>
                </div>
                <div className="col-span-3">
                  <TextField
                    value={initialStartTime}
                    label={t('START_TIME')}
                    type={"datetime-local"}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(value) => setInitialStartTime(value.target.value)}
                  />
                </div>
                <div className="col-span-3">
                  <TextField
                    value={initialEndTime}
                    label={t('END_TIME')}
                    type={"datetime-local"}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(value) => setInitialEndTime(value.target.value)}
                  />
                </div>
              </Box>
              {getHourDifferences(initialStartTime, initialEndTime) > 8 &&
                <Typography textAlign="center" color="red">{t('OVER_8_HOURS_WARNING')}</Typography>}

              <div className="flex">
                <FormControlLabel
                  label={t('ENABLE_RECURRING_VISIT')}
                  control={
                    <Switch
                      checked={recurScheduleMode}
                      onChange={onChangeRecurScheduleMode}
                      name={t('ENABLE_RECURRING_VISIT')}
                      color="primary"
                    />}
                />
                {recurScheduleMode && <FormControlLabel
                  label={t('SKIP_WEEKENDS')}
                  control={
                    <Switch
                      checked={excludeWeekend}
                      onChange={onChangeExcludeWeekend}
                      name={t('SKIP_WEEKENDS')}
                      color="primary"
                    />}
                  sx={{ marginLeft: 2 }}
                />}
              </div>

              {recurScheduleMode &&
                <>
                  <div className="grid mt-4">
                    <Typography variant={"body1"} sx={{ marginTop: 2, marginRight: 2, width: '100px' }}>
                      {t('RECURRENCE')}
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel>{t('RECURRENCE')}</InputLabel>
                      <Select
                        label={t('RECURRENCE')}
                        sx={{ width: 200 }}
                        value={recurrence}
                        onChange={onChangeReccurence}
                      >
                        <MenuItem value={0}>{t('DAILY')}</MenuItem>
                        <MenuItem value={1}>{t('WEEKLY')}</MenuItem>
                        <MenuItem value={2}>{t('MONTHLY')}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex mt-4">
                    <Typography variant={"body1"} sx={{ marginTop: 1, marginRight: 1 }}>
                      {t('REPEAT_FOR')}
                    </Typography>
                    <TextField
                      value={repeatTimes}
                      hiddenLabel
                      type="number"
                      variant="outlined"
                      sx={{ width: 80 }}
                      size={"small"}
                      onChange={(value) => setRepeatTimes(value.target.value)}
                    />
                    <Typography variant={"body1"} sx={{ marginTop: 1, marginLeft: 1 }}>{t('TIMES')}</Typography>
                    <Typography variant={"body1"} sx={{ marginTop: 1, marginLeft: 1 }}>(from {DateUtils.getDayDefinition(new Date(initialStartTime))})</Typography>
                    {/* <Divider orientation="vertical" sx={{marginX: 2}} /> */}
                    <Typography variant={"body1"} sx={{ marginTop: 1, marginRight: 2, marginLeft: 1 }}>
                      {t('OF_EVERY')}
                    </Typography>
                    <TextField
                      value={timeInterval}
                      hiddenLabel
                      type="number"
                      variant="outlined"
                      sx={{ width: 80 }}
                      size={"small"}
                      onChange={(value) => setTimeInterval(value.target.value)}
                    />
                    <Typography variant={"body1"} sx={{ marginTop: 1, marginLeft: 1 }}>
                      {recurrence == RecurrenceMode.DAILY ? t('DAY_S') :
                        recurrence == RecurrenceMode.WEEKLY ? t('WEEK_S') :
                          recurrence == RecurrenceMode.MONTHLY && t('MONTH_S')}</Typography>
                  </div>
                </>}
              <div className="flex justify-end mt-4">
                <Typography variant="caption" color="red" sx={{ marginTop: 1 }}>
                  {t('OVERWRITE_WARNING')}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={onGenerateTimeSlot}
                  sx={{ marginLeft: 2 }}
                  disabled={+timeInterval <= 0 || +repeatTimes <= 0}
                >
                  {t('GENERATE_TIME_SLOTS')}
                </Button>
              </div>
              <Divider sx={{ marginTop: 1 }} />
            </TabPanel>

            {/* Tab Panel 2 */}
            <TabPanel value={tabValue} index={1}>
              {visitTimeList.map((vt: any, index: number) => (
                <>
                  {/* <Typography variant="body1" sx={{ textAlign: 'center' }}>{DateUtils.getDayDefinition(new Date(vt.startTime))}.</Typography> */}
                  <Box className="grid grid-cols-8 gap-2 px-4 py-2 pl-0">
                    <div className="pt-3 rounded-md border-sky-500 text-sky-500 border-2 text-center" style={{ height: 56, width: '100%' }}>
                      <Typography variant="h6">{index + 1}.{DateUtils.getDayDefinition(new Date(vt.startTime)).substring(0, 2).toUpperCase()}</Typography>
                    </div>
                    <div className="col-span-3">
                      <TextField
                        value={vt.startTime}
                        label={t('START_TIME')}
                        type={"datetime-local"}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(value) => {
                          visitTimeList[index].startTime = value.target.value;
                          setRefreshDisplay([]);
                        }}
                      />
                    </div>
                    <div className="col-span-3">
                      <TextField
                        value={vt.endTime}
                        label={t('END_TIME')}
                        type={"datetime-local"}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(value) => {
                          visitTimeList[index].endTime = value.target.value;
                          setRefreshDisplay([]);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="error"
                        disabled={targetVisit}
                        sx={{ width: "100%", height: 56 }}
                        onClick={() => {
                          visitTimeList.splice(index, 1);
                          setRefreshDisplay([]);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Box>
                  {getHourDifferences(vt.startTime, vt.endTime) > 8 &&
                    <Typography textAlign="center" color="red">{t('OVER_8_HOURS_WARNING')}</Typography>}
                  {/* Show only the traineeship is selected or is update */}
                  {((+orgStudentId != 0 && +traineeshipId != 0) || targetVisit) &&
                    <div className="grid grid-cols-5 gap-2">
                      <div className="col-span-1">
                        <div className="pt-2 rounded-md border-sky-500 text-sky-500 border-2 text-center" style={{ width: '100%', height: 38 }}>
                          <Typography variant="body2">UNITS ({vt.unitList.length})</Typography>
                        </div>
                      </div>
                      <div className="col-span-4">
                        {academicStore.loadingCompulosories ? <Box textAlign={"center"}><CircularProgress /></Box>
                          : <div className="border flex overflow-x-auto p-2 rounded-md w-full">
                            {academicStore.unitQualificationRelationOptionList.data
                              .map((unit: any, unitIndex: number) => (
                                <button
                                  type="button"
                                  className={`hover:bg-highlight hover:text-white text-center px-1 rounded-md text-sm mr-2
                                  ${vt.unitList.includes(unit.id) && "bg-highlight text-white"}`}
                                  key={`unit_select_${unitIndex}`}
                                  onClick={() => {
                                    if (vt.unitList.includes(unit.id)) {
                                      let i = vt.unitList.indexOf(unit.id);
                                      vt.unitList.splice(i, 1);
                                    } else vt.unitList.push(unit.id);
                                    setRefreshDisplay([]);
                                  }}
                                >
                                  {unit.code}
                                </button>
                              ))}
                          </div>}
                      </div>
                    </div>}
                  <Divider sx={{ marginY: 2 }} />
                </>
              ))}
              {visitTimeList.length == 0 &&
                <Typography textAlign="center">{t('NO_TIME_SLOTS')}</Typography>}
            </TabPanel>
          </div>
        </Box>
      </div >
      <div className="text-right mt-4">
        {confirmButton ?
          <>
            <Button
              variant="outlined"
              disabled={visitName === "" || description === "" || staffId === "0" ||
                visitTimeList.length === 0 || +orgId == 0}
              onClick={() => onClickAction({
                visitName,
                description,
                orgId,
                staffId,
                visitTimeList,
                traineeshipId
              })}
              color="success"
            >
              {targetVisit ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setConfirmButton(false)}
              sx={{ marginLeft: 2 }}
              color="error"
            >
              {t('CANCEL')}
            </Button>
          </>
          :
          <Button
            variant="outlined"
            disabled={visitName === "" || description === "" || +staffId == 0 ||
              visitTimeList.length === 0 || +orgId == 0}
            onClick={() => setConfirmButton(true)}
          >
            {targetVisit ? t('UPDATE_VISIT') : t('CREATE_VISIT')}
          </Button>}
      </div>
    </>
  )
});

export default VisitAction;
