import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import { FormGroup, Input, Card, CardHeader, CardContent, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, IconButton } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, GetCurrentSemester, GetUserId, isBrowser, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import VisitAction from "../../components/visit-action-component/visit-action-component";
import { toJS } from "mobx";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

import FilePresentIcon from '@mui/icons-material/FilePresent';

// icons 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SlideDown from "react-slidedown";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import TabPanel from "../../components/shared-tab-panel";
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowRight from "@mui/icons-material/ArrowRight";
import axios from "axios";
import PlaceIcon from '@mui/icons-material/Place';
import { navigate } from "gatsby";

const OrganisationPage = observer(({ location }) => {
  const { rootStore, academicStore, calendarStore, groupStore, teacherStore, userStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showStudentModal, setShowStudentModal] = useState<boolean>(false);
  const [showVisitModal, setShowVisitModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [studentVisitDeleteModal, setStudentVisitDeleteModal] = useState<"" | "Student" | "Visit">("");
  // Determine is update or create
  const [currentGroupId, setCurrentGroupId] = useState<number>(0);
  const [currentOrgId, setCurrentOrgId] = useState<number>(0);
  const [viewMode, setViewMode] = useState<"" | "Student" | "Visit">("");
  const [targetOrganisation, setTargetOrganisation] = useState<any>();
  const [targetStudent, setTargetStudent] = useState<any>();
  const [targetVisit, setTargetVisit] = useState<any>();
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  // Organisation Students
  const [yearId, setYearId] = useState<string>("0");
  const [semesterId, setSemesterId] = useState<string>("0");
  const [subjectId, setSubjectId] = useState<string>("0");
  const [subjectClassId, setSubjectClassId] = useState<string>("0");
  const [studentIdList, setStudentIdList] = useState<any>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  // Visit
  const [visitName, setVisitName] = useState<string>("");
  const [visitDescription, setVisitDescription] = useState<string>("");
  const [orgStudentId, setOrgStudentId] = useState<number>(0);
  const [staffId, setStaffId] = useState<string>("0");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [onRefreshFunction, setOnRefreshFunction] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [orgStudentPage, setOrgStudentPage] = useState<number>(1);
  const [visitPage, setVisitPage] = useState<number>(1);
  
  // Trainee Visit List
  const [traineeVisitListModal, setTraineeVisitListModal] = useState<number>(0);
  const [currentTrainee, setCurrentTrainee] = useState<number>(0);


  //trainneship
  const [traineeshipTabValue, setTraineeshipTabValue] = useState<number>(1);
  const [currentTraineeShip, setCurrentTraineeShip] = useState<any>();
  const [currentTraineeShipPage, setCurrentTraineeShipPage] = useState<any>(0);

  // visit tab: fold / unfold
  const [fold, setFold] = useState<number>(0);
  const [currentVisit, setCurrentVisit] = useState<any>();

  // create visit request
  const [createVisitModal, setCreateVisitModal] = useState<boolean>(false); // modal display
  const [currentCreateVisit, setCurrentCreateVisit] = useState<any>(); // data store

  // state for accept/reject 
  // crar = change request accept reject
  const [crar, setCrar] = useState<boolean>();


  useEffect(() => {
    let groupId = 0;
    if (location && location.state && location.state.groupId) {
      groupId = location.state.groupId;
    }
    groupStore.getGroupList(0, 0).then((res) =>
      onToggleOrganisationList(groupId == 0 ? res.data[0].id : groupId));
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) != calendarStore.calendarSettingList.data.length) {
      calendarStore.getCalendarSettingList(0, 0);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) != teacherStore.teacherProfileList.data.length) {
      teacherStore.getTeacherProfileList(0, 0);
    }

  }, []);

  const onChangeSubject = (event: SelectChangeEvent) => {
    setSubjectId(event.target.value as string);
    academicStore.getSubjectClassListBySubjectId(parseInt(event.target.value), 0, 0)
      .then((res) => {
        if (res.data.length > 0) {
          setSubjectClassId(res.data[0].id);
          groupStore.getStudentListByClassId(parseInt(res.data[0].id), 0, 0)
        }
        else setSubjectClassId("0");
      });
  };

  const onChangeSubjectAutoComplete = (subjectCode: string) => {
    if (academicStore.subjectList.data.length > 0) {
      let subject = academicStore.subjectList.data.find(s => subjectCode === s.subjectCode);
      console.log(subject, subjectCode);
      if (subject) {
        setSubjectId(subject.id);
        academicStore.getSubjectClassListBySubjectId(subject.id, 0, 0)
          .then((res) => {
            if (res.data.length > 0) {
              setSubjectClassId(+subjectClassId != 0 ? subjectClassId : res.data[0].id);
              if (!targetStudent) groupStore.getStudentListByClassId(parseInt(res.data[0].id), 0, 0)
            }
            else setSubjectClassId("0");
          });
      }
    }
  }
  const onChangeSubjectClass = (event: SelectChangeEvent) => {
    groupStore.getStudentListByClassId(parseInt(event.target.value), 0, 0)
    setSubjectClassId(event.target.value as string);
  };
  const onChangeYear = (event: SelectChangeEvent) => {
    setYearId(event.target.value as string);
  };
  const onChangeSemester = (event: SelectChangeEvent) => {
    setSemesterId(event.target.value as string);
  };
  const onChangeStaff = (event: SelectChangeEvent) => {
    setStaffId(event.target.value as string);
  };

  const onToggleOrganisationList = (id: number) => {
    setPage(1);
    setOrgStudentPage(1);
    setVisitPage(1);
    groupStore.getOrganisationListByGroupId(id, PAGE_SIZE, 0);
    setCurrentGroupId(id);
    setCurrentOrgId(0);
  }

  const onActionOrganisation = () => {
    let req: any = {
      id: 0,
      name,
      address,
      tel,
      email,
      mobile,
      contactPerson,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      groupId: currentGroupId,
    }
    if (targetOrganisation) {
      req.id = targetOrganisation.id;
      groupStore.updateOrganisation(req).then(() => {
        rootStore.notify(t('ORGANISATION') + t('UPDATED_B'), 'success');
        onToggleOrganisationList(currentGroupId);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      groupStore.createOrganisation(req).then(() => {
        rootStore.notify(t('ORGANISATION') + t('CREATED_B'), 'success')
        onToggleOrganisationList(currentGroupId);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const onActionStudent = () => {
    let req: any = {
      id: 0,
      semesterId,
      subjectClassId,
      startDate: startDate + "T00:00:00",
      endDate: endDate + "T00:00:00",
      organisationId: currentOrgId,
      studentIdList,
    }
    if (targetStudent) {
      req.id = targetStudent.id;
      req.studentId = studentIdList[0];
      groupStore.updateOrganisationStudent(req).then(() => {
        rootStore.notify(t('STUDENT_IN_ORGANISATION') + t('UPDATED_B'), 'success');
        groupStore.getOrganisationStudentListByOrgId(currentOrgId, GetCurrentSemester().semester, PAGE_SIZE, (orgStudentPage - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'success'));
    } else {
      groupStore.createOrganisationStudent(req).then(() => {
        rootStore.notify(t('STUDENT_IN_ORGANISATION') + t('CREATED_B'), 'success')
        groupStore.getOrganisationStudentListByOrgId(currentOrgId, GetCurrentSemester().semester, PAGE_SIZE, 0);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'success'));
    }
  }

  const onActionVisit = (reqInfo: any) => {
    if (targetVisit) {
      let req: any = {
        name: reqInfo.visitName,
        description: reqInfo.description,
        id: targetVisit.id,
        startTime: reqInfo.visitTimeList[0].startTime,
        endTime: reqInfo.visitTimeList[0].endTime,
        traineeshipId: targetVisit.traineeshipId,
        locationId: reqInfo.orgId,
        conductTrainerId: reqInfo.staffId,
        unitIdList: reqInfo.visitTimeList[0].unitList,
        createTime: targetVisit.createTime,
      }
      groupStore.updateVisit(req).then(() => {
        rootStore.notify(t('VISIT') + t('UPDATED_B'), 'success');
        groupStore.getVisitListByOrgId(currentOrgId, PAGE_SIZE, (visitPage - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      let tempReqList: any[] = [];
      reqInfo.visitTimeList.map((vt: any) => {
        tempReqList.push({
          name: reqInfo.visitName,
          description: reqInfo.description,
          startTime: vt.startTime,
          endTime: vt.endTime,
          traineeshipId: reqInfo.traineeshipId,
          locationId: reqInfo.orgId,
          conductTrainerId: reqInfo.staffId,
          unitIdList: vt.unitList,
        });
      })
      console.log(tempReqList);
      groupStore.createVisit(tempReqList).then(() => {
        rootStore.notify(t('VISIT') + t('CREATED_B'), 'success')
        groupStore.getVisitListByOrgId(currentOrgId, PAGE_SIZE, 0);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = groupStore.organisationList.data.find(o => o.id == id);
      setTargetOrganisation(target);
      setName(target.name);
      setAddress(target.address);
      setEmail(target.email);
      setContactPerson(target.contactPerson);
      setMobile(target.mobile);
      setTel(target.tel);
      setLatitude(target.latitude);
      setLongitude(target.longitude);
    }
    setShowModal(true);
  }

  const handleShowStudentModal = (id?: any) => {
    if (id) {
      let target = groupStore.orgStudentList.data.find(o => o.id == id);
      setTargetStudent(target);
      academicStore.getSubjectClassListBySubjectId(target.subjectId, 0, 0);
      for (var year of calendarStore.calendarSettingList.data) {
        if (year.schoolSemesters.find(ss => ss.id == target.semesterId)) {
          setYearId(year.id);
          break;
        }
      }
      setSemesterId(target.semesterId);
      setSubjectId(target.subjectId);
      setSubjectClassId(target.subjectClassId);
      setStartDate(target.startDate.split('T')[0]);
      setEndDate(target.endDate.split('T')[0]);
      setStudentIdList([target.studentId]);
    }
    setShowStudentModal(true);
  }

  const handleShowVisitModal = (id?: any) => {
    groupStore.getOrganisationStudentListByOrgId(currentOrgId, 0, 0);
    if (id) {
      let target = groupStore.visitList.data.find(v => v.id == id);
      setTargetVisit(target);
      setVisitName(target.name);
      setVisitDescription(target.description);
      setOrgStudentId(target.organisationStudentId);
      setStartTime(target.startTime ? target.startTime : "");
      setEndTime(target.endTime ? target.endTime : "");
      setStaffId(target.staffId);
    }
    setShowVisitModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowStudentModal(false);
    setShowDeleteModal(false);
    setShowVisitModal(false);
    setStudentVisitDeleteModal("");
    setTargetOrganisation(null);
    setTargetStudent(null);
    setTargetVisit(null);
    setName("");
    setAddress("");
    setContactPerson("");
    setMobile("");
    setTel("");
    setLatitude("");
    setLongitude("");
    setEmail("");
    setYearId("0");
    setSemesterId("0");
    setSubjectClassId("0");
    setSubjectId("0");
    setStartDate("");
    setEndDate("");
    setStudentIdList([]);
    setVisitName("");
    setVisitDescription("");
    setOrgStudentId(0);
    setStaffId("0");
    setStartTime("");
    setEndTime("");
  }

  const handleDeleteModal = (id: number) => {
    let target = groupStore.organisationList.data.find(o => o.id == id);
    setTargetOrganisation(target);
    setShowDeleteModal(true);
  }

  const handleStudentVisitDeleteModal = (id: number, isVisit?: boolean) => {
    let target: any = {};
    if (isVisit) {
      target = groupStore.visitList.data.find(v => v.id == id);
      setTargetVisit(target);
    } else {
      target = groupStore.orgStudentList.data.find(o => o.id == id);
      setTargetStudent(target);
    }
    setStudentVisitDeleteModal(isVisit ? "Visit" : "Student");
  }

  //june
  const handleTraineeshipListModal = (id: number) => {
    groupStore.getStudentProfileById(id);
    setCurrentTraineeShip(id)

  }

  const handleTraineeVisitListModal = (visit: any) => {
    groupStore.getVisitListByTraineeshipId(visit.id);
    setCurrentVisit(visit);
  }

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      var delayInMilliseconds = 500; //1 second
      groupStore.setVisitTableLoading(true);
      setTimeout(function () {
        groupStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      //visitStore.getChangeRequestListByVisitId(id);
      setFold(id)
      // onToggleChangeVisitList(id)
    }
  }

  const getLatLong = async (addr: string) => {
    const APIKey = "AIzaSyCTPrQJK-VE4UKIavwG41HkRXbJFpT3Ngw";
    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${addr}&key=${APIKey}`)
      .then((res: any) => {
        setLongitude(res.data.results[0].geometry.location.lng);
        setLatitude(res.data.results[0].geometry.location.lat);
        console.log(res);
      }).catch((err: any) => {
        console.error(err);
      })
  }



  return (
    <ContentLayout
      pageName={t('ORGANISATION')}
      pageHeading={t('ORGANISATION')}
      breadCrumb={[
        { label: t('GROUP') + t('SETTING_B'), to: "/group/overview" },
        { label: t('ORGANISATION'), to: "" }
      ]}
      buttonLabel={t('ADD_A') + t('ORGANISATION')}
      onClickAction={() => handleShowModal()}
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="max-h-96 overflow-auto">
          {
            groupStore.loading && groupStore.groupList.length < 1 ?
              <>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
              </>
              :
              groupStore.groupList.data.map((group: any, index: number) => (
                <Box key={`group_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentGroupId == group.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleOrganisationList(group.id)}
                  >
                    {group.name}
                  </button>
                </Box>
              ))
          }
        </div>
        <div className="col-span-3">
          <VmTable
            loading={groupStore.loadingOrg}
            page={page}
            paginationCount={groupStore.organisationList.totalCount == 0 ? 1 : groupStore.organisationList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setPage(pageNumber);
              groupStore.getOrganisationListByGroupId(currentGroupId, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('ORGANISATION_NAME'), 'Default User', t('ACTION')]}
            tbody={groupStore.organisationList.data.length > 0 &&
              groupStore.organisationList.data.map((organisation: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`organisation_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{organisation.name}</td>
                    <td className={TD_NORMAL}>
                      <Typography>Username: {organisation.email}</Typography>
                      {/* <Typography>Password: {(isBrowser && localStorage.USER_INFO) && JSON.parse(localStorage.USER_INFO).employerDefaultPassword}</Typography> */}
                      <Typography>Password: ***</Typography>
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowModal(organisation.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          setCurrentOrgId(organisation.id);
                          setViewMode("Student");
                          setOrgStudentPage(1);
                          groupStore.getOrganisationStudentListByOrgId(organisation.id, PAGE_SIZE, 0);
                        }}
                      >
                        {t('VIEW_STUDENTS')}
                      </Button>
                      {/* <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          setCurrentOrgId(organisation.id);
                          setViewMode("Visit");
                          setVisitPage(1);
                          groupStore.getVisitListByOrgId(organisation.id, PAGE_SIZE, 0);
                        }}
                      >
                        {t('VIEW_VISITS')}
                      </Button> */}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(organisation.id)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </div>
      </div>

      {/* Student Table */}
      {currentOrgId > 0 && viewMode === "Student" &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('Trainee List')}
            </Typography>
            {/* <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowStudentModal()}
            >
              {t('ADD_A') + t('STUDENT')}
            </VmButton> */}
          </div>
          <VmTable
            loading={groupStore.loadingStudentList}
            page={orgStudentPage}
            paginationCount={groupStore.orgStudentList.totalCount == 0 ? 1 : groupStore.orgStudentList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setOrgStudentPage(pageNumber);
              groupStore.getOrganisationStudentListByOrgId(currentOrgId, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('FIRST_NAME'), t('LAST_NAME'), t('BOD'), t('EMAIL'), "Action"]}
            tbody={
              groupStore.orgStudentList.data.length > 0 &&
              groupStore.orgStudentList.data.map((student: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`organisation_student_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{student.firstName}</td>
                    <td className={TD_NORMAL}>{student.lastName}</td>
                    <td className={TD_NORMAL}>{student.birthDate && student.birthDate.split('T')[0]}</td>
                    <td className={TD_LAST}>{student.emailAddress}</td>
                    <td className={TD_LAST}>
                      {/* <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowStudentModal(student.id)}
                      >
                        {t('EDIT')}
                      </Button> */}
                      {/* <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleStudentVisitDeleteModal(student.id)}
                      >
                        {t('DELETE')}
                      </Button> */}
                      <Button
                        variant="outlined"
                        onClick={() => navigate("/traineeship/overview", {
                          state: { inputPage: page, traineeId: student.id}
                        })}
                      >
                        View Enrollment Form & Traineeship
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </>}

      {/* Visit Table */}
      {currentOrgId > 0 && viewMode === "Visit" &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>{t('VISITS')}</Typography>
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowVisitModal()}
            >
              {t('ADD_A') + t('VISIT')}
            </VmButton>
          </div>
          <VmTable
            loading={groupStore.loadingVisitList}
            page={visitPage}
            paginationCount={groupStore.visitList.totalCount == 0 ? 1 : groupStore.visitList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setVisitPage(pageNumber);
              groupStore.getVisitListByOrgId(currentOrgId, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('UPDATED_TIME'), t('ACTION')]}
            tbody={
              groupStore.visitList.data.length > 0 &&
              groupStore.visitList.data.map((visit: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`organisation_Visit_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{visit.name}</td>
                    <td className={TD_NORMAL}>{visit.finalStartTime.split('T')[0] + " " + visit.finalStartTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.finalEndTime.split('T')[0] + " " + visit.finalEndTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.updateTime.split('T')[0] + " " + visit.updateTime.split('T')[1]}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowVisitModal(visit.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleStudentVisitDeleteModal(visit.id, true)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </>}

      {/* Org Modal */}
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetOrganisation ? t('UPDATE_A') + t('ORGANISATION') : t('ADD_A') + t('ORGANISATION')}
        buttonLabel={targetOrganisation ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetOrganisation ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionOrganisation}
        disabledCondition={name === "" || address === "" || email === "" || tel === "" || mobile === "" ||
          contactPerson === "" || longitude === "" || latitude === ""}
        width={500}
        buttonLoading={groupStore.loading}
      >
        <TextField
          value={name}
          label={t('ORGANISATION_NAME') + " *"}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setName(value.target.value)}
        />
        <TextField
          value={address}
          label={t('ADDRESS') + " *"}
          variant="outlined"
          sx={{ width: '100%' }}
          onChange={(value) => setAddress(value.target.value)}
          InputProps={{
            endAdornment:
              (<InputAdornment position="end">
                <IconButton edge="end" color="primary" onClick={() => getLatLong(address)}>
                  <PlaceIcon color="error" />
                </IconButton>
              </InputAdornment>)
          }}
        />
        <></>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <TextField
            value={longitude}
            label={t('LONGITUDE') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLongitude(value.target.value)}
          />
          <TextField
            value={latitude}
            label={t('LATITUDE') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLatitude(value.target.value)}
          />
          <TextField
            value={tel}
            label={t('TEL') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTel(value.target.value)}
          />
          <TextField
            value={mobile}
            label={t('MOBILE') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setMobile(value.target.value)}
          />

          <TextField
            value={email}
            label={t('EMAIL') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setEmail(value.target.value)}
          />
          <TextField
            value={contactPerson}
            label={t('CONTACT_PERSON') + " *"}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setContactPerson(value.target.value)}
          />
        </div>
        {!targetOrganisation && <div className="col-span-2 mt-2">
          <Typography variant="caption">
            <span className="text-red-500 mr-1">IMPORTANT: </span>
            Default account will be created for this employer with your <b> employer email</b> as default username and default password <b>{(isBrowser && localStorage.USER_INFO) && JSON.parse(localStorage.USER_INFO).employerDefaultPassword}</b>
          </Typography>
        </div>}
      </VmModal>

      {/* Org Student Modal */}
      {currentOrgId > 0 &&
        <VmModal
          openModal={showStudentModal}
          onClose={handleCloseModal}
          title={targetStudent ? t('UPDATE_A') + t('STUDENT') : t('ADD_A') + t('STUDENT')}
          buttonLabel={targetStudent ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
          buttonLabelWithoutConfirm={targetStudent ? t('UPDATE_NOW') : t('CREATE_NOW')}
          onClickConfirmedButton={onActionStudent}
          disabledCondition={academicStore.subjectClassList.length < 1 || calendarStore.calendarSettingList.length < 1 ||
            startDate === "" || endDate === "" || studentIdList.length < 1 || currentOrgId < 1}
          width={500}
          buttonLoading={groupStore.loading}
        >
          {/* {targetStudent && <Typography variant="subtitle1" sx={{ marginTop: -1, marginBottom: 2 }}>
            {t('CURRENT_A') + t('SUBJECT')}: {academicStore.subjectList.data.find(s => s.id == subjectId) && academicStore.subjectList.data.find(s => s.id == subjectId).subjectCode}
          </Typography>} */}
          <div className="grid grid-cols-2 gap-4">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={academicStore.subjectList.data.find(s => s.id == subjectId) ? academicStore.subjectList.data.find(s => s.id == subjectId).subjectCode : ""}
              options={academicStore.subjectList.data.map((s) => ({ label: s.subjectCode, id: s.id }))}
              sx={{ width: '100%' }}
              onInputChange={(event, newInputValue) => onChangeSubjectAutoComplete(newInputValue)}
              renderInput={(params) =>
                <TextField  {...params} label={t('SUBJECT')} />}
            />
            {/* <FormControl fullWidth>
              <InputLabel>{t('SUBJECT')}</InputLabel>
              <Select
                label={t('SUBJECT')}
                sx={{ width: '100%' }}
                value={subjectId}
                onChange={onChangeSubject}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {
                  academicStore.subjectList.data.length > 0 &&
                  academicStore.subjectList.data.map((subj: any, index: number) => (
                    <MenuItem key={`subject_select_${index}`} value={subj.id}>
                      {subj.subjectCode}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}

            {
              academicStore.loading ? <div className="text-center"><CircularProgress /></div>
                :
                <FormControl fullWidth>
                  <InputLabel>{t('SUBJECT_CLASS')}</InputLabel>
                  <Select
                    label={t('SUBJECT_CLASS')}
                    sx={{ width: '100%' }}
                    value={subjectClassId}
                    disabled={subjectId === "0"}
                    onChange={onChangeSubjectClass}
                  >
                    <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                    {
                      academicStore.subjectClassList.data.length > 0 &&
                      academicStore.subjectClassList.data.map((subjClass: any, index: number) => (
                        <MenuItem key={`subject_select_${index}`} value={subjClass.id}>
                          {subjClass.classCode}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
            }

            {subjectId !== "0" && subjectClassId !== "0" && !targetStudent &&
              <div className="col-span-2">
                <Typography>{t('STUDENT_LIST')}: {groupStore.classStudentList.classCode && groupStore.classStudentList.classCode}</Typography>
                <div className="flex my-2">
                  <VmButton
                    className="bg-highlight text-white mr-4 px-4"
                    style={{ height: 'fit-content', width: 'fit-content' }}
                    onClick={() => {
                      // tick all students in the list (filtered out existed student in organisation table)
                      setStudentIdList(groupStore.classStudentList.data.studentList &&
                        groupStore.classStudentList.data.studentList
                          .filter(s => groupStore.orgStudentList.data.map(o => o.studentId).includes(s.id) == false)
                          .map(s => s.id));
                    }}
                  >
                    {t('SELECT_ALL')}
                  </VmButton>
                  <VmButton
                    className="bg-highlight text-white px-4"
                    style={{ height: 'fit-content', width: 'fit-content' }}
                    onClick={() => setStudentIdList([])}
                  >
                    {t('UNSELECT_ALL')}
                  </VmButton>
                </div>
                <div className="border p-2 pt-1 max-h-40 overflow-auto">
                  {groupStore.loadingStudentList ? <div className="text-center"><CircularProgress /></div>
                    : groupStore.classStudentList.data.studentList &&
                    groupStore.classStudentList.data.studentList
                      // filtered out existed student in organisation table
                      .filter(s => groupStore.orgStudentList.data.map(o => o.studentId).includes(s.id) == false)
                      .map((s: any, index: number) => (
                        <button
                          type="button"
                          className={`hover:bg-highlight hover:text-white w-full text-left px-1 rounded-md text-sm
                        ${studentIdList.includes(s.id) && "bg-highlight text-white"}`}
                          key={`student_select_${index}`}
                          onClick={() => {
                            let tempIdList = studentIdList;
                            if (tempIdList.includes(s.id)) {
                              let i = tempIdList.indexOf(s.id);
                              tempIdList.splice(i, 1);
                            } else {
                              tempIdList.push(s.id);
                            }
                            setStudentIdList(tempIdList);
                            setOnRefreshFunction([]);
                          }}
                        >
                          {s.firstName + " " + s.lastName}
                        </button>
                      ))}
                </div>
                <Typography variant={"body2"} textAlign="center" marginTop={1}>
                  <span className="text-red-500 mr-1">{studentIdList.length}</span>
                  <span className="">
                    / {groupStore.classStudentList.data.studentList && groupStore.classStudentList.data.studentList
                      .filter(s => groupStore.orgStudentList.data.map(o => o.studentId).includes(s.id) == false).length}
                    {studentIdList.length > 1 ? ' ' + t('STUDENTS_SINGLE') : ' ' + t('STUDENT_SINGLE')} {t('SELECTED_B')}
                  </span>
                </Typography>
              </div>
            }

            <FormControl fullWidth>
              <InputLabel>{t('YEAR')}</InputLabel>
              <Select
                label={t('YEAR')}
                sx={{ width: '100%' }}
                value={yearId}
                onChange={onChangeYear}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {
                  calendarStore.calendarSettingList.data.length > 0 &&
                  calendarStore.calendarSettingList.data.map((year: any, index: number) => (
                    <MenuItem key={`year_select_${index}`} value={year.id}>
                      {year.yearDescription}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>{t('SEMESTER')}</InputLabel>
              <Select
                label={t('SEMESTER')}
                sx={{ width: '100%' }}
                value={semesterId}
                disabled={yearId === "0"}
                onChange={onChangeSemester}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {yearId !== "0" &&
                  calendarStore.calendarSettingList.data.find(y => y.id == yearId).schoolSemesters
                    .map((semester: any, index: number) => (
                      <MenuItem key={`semester_select_${index}`} value={semester.id}>
                        {semester.semesterDescription}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <TextField
              value={startDate}
              label={t('ENROLMENT_START_DATE')}
              type="date"
              variant="outlined"
              disabled={+yearId == 0}
              // min/max range date is limited to the selected academic year range
              InputProps={{
                inputProps: {
                  min: +yearId > 0 && calendarStore.calendarSettingList.data.find(c => c.id == +yearId).startDate.split('T')[0],
                  max: +yearId > 0 && calendarStore.calendarSettingList.data.find(c => c.id == +yearId).endDate.split('T')[0]
                }
              }}
              sx={{ width: '100%' }}
              onChange={(value) => setStartDate(value.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={endDate}
              label={t('ENROLMENT_END_DATE')}
              type="date"
              variant="outlined"
              disabled={+yearId == 0}
              // min/max range date is limited to the selected academic year range
              InputProps={{
                inputProps: {
                  min: +yearId > 0 && calendarStore.calendarSettingList.data.find(c => c.id == +yearId).startDate.split('T')[0],
                  max: +yearId > 0 && calendarStore.calendarSettingList.data.find(c => c.id == +yearId).endDate.split('T')[0]
                }
              }}
              sx={{ width: '100%', marginBottom: 2 }}
              onChange={(value) => setEndDate(value.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box className="col-span-2" sx={{ marginTop: -2 }}>
              <Typography variant="caption">{t('TIP_STUDENT_ENROLLMENT_PERIOD')}</Typography>
            </Box>

          </div>
        </VmModal>}

      {/* Visit Modal */}
      {currentOrgId > 0 &&
        <VmModal
          openModal={showVisitModal}
          onClose={handleCloseModal}
          title={targetVisit ? t('UPDATE_A') + t('VISIT') : t('ADD_A') + t('VISIT')}
          showButton={false}
          width={1200}
        >
          <VisitAction
            onClickAction={onActionVisit}
            targetVisit={targetVisit}
          />
        </VmModal>}

      {/* Delete Modal */}
      <VmModal
        openModal={showDeleteModal || studentVisitDeleteModal === "Student" || studentVisitDeleteModal === "Visit"}
        onClose={handleCloseModal}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={groupStore.loading}
        onClickConfirmedButton={() => {
          if (showDeleteModal) {
            groupStore.deleteOrganisation(targetOrganisation.id).then(() => {
              rootStore.notify(t('ORGANISATION') + t('DELETED_B'), 'success');
              onToggleOrganisationList(currentGroupId);
              handleCloseModal();
            }).catch(() => rootStore.notify(t('Delete failed, there are other records has linked to this employer'), 'error'));
          } else if (studentVisitDeleteModal === "Visit") {
            groupStore.deleteVisit(targetVisit.id).then(() => {
              rootStore.notify(t('VISIT') + t('DELETED_B'), 'success');
              groupStore.getVisitListByOrgId(currentOrgId, PAGE_SIZE, 0);
              handleCloseModal();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          } else {
            groupStore.deleteOrganisationStudent(targetStudent.id).then(() => {
              rootStore.notify(t('STUDENT_IN_ORGANISATION') + t('DELETED_B'), 'success');
              groupStore.getOrganisationStudentListByOrgId(currentOrgId, PAGE_SIZE, 0);
              handleCloseModal();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          }
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}: {showDeleteModal ? t('ORGANISATION') : studentVisitDeleteModal === "Student" ? t('STUDENT_SINGLE') : t('VISIT')}?</Typography>
        {/* <Typography color="red" variant="caption">
          {t('Trainee Visit List')}
        </Typography> */}
      </VmModal>



      {/* Traineeship List */}
      {currentTraineeShip > 0 &&
        <>
          <div className="flex justify-between my-4">
            <Typography variant="h5" fontWeight={'bold'} marginTop={1}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('Traineeship List')}: {groupStore.currentStudentProfile.totalCount > 0 && groupStore.currentStudentProfile.data.firstName + " " + groupStore.currentStudentProfile.data.lastName}
            </Typography>
          </div>
          <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
            <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
            <Typography>Trainer & Assesor Not Check In Yet</Typography>
            <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check In By Trainer & Assesor</Typography>
            <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check Out By Trainer & Assesor</Typography>
          </Box>
          <VmTable
            loading={groupStore.loadingTraineeship}
            page={0}
            // paginationCount={groupStore.currentStudentProfile.totalCount == 0 ? 1 : studentStore.visitHistoryList.totalCount / PAGE_SIZE}
            // onChangePagination={(event, pageNumber) => {
            //   setVisitHistoryPage(pageNumber);
            //   //studentStore.getVisitListByStudentId(currentStudent.id, true, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            // }}
            thead={["ID", "Enrolled In Qualification", "Qualification name", "Trainer & Assesor", "Employer", t('ACTION')]}
            tbody={
              // studentStore.visitHistoryList.data.length > 0 ?
              // studentStore.visitHistoryList.data.map((visit: any, index: number) => (
              groupStore.currentStudentProfile.traineeships &&
                groupStore.currentStudentProfile.traineeships.length > 0 ?
                groupStore.currentStudentProfile.traineeships.map((visit: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`}>
                      <td className={TD_FIRST}>{index + 1}</td>
                      <td className={TD_NORMAL}>{visit.qualification.code}</td>
                      <td className={TD_NORMAL}>{visit.qualification.description}</td>
                      <td className={TD_NORMAL}>{visit.trainer ? `${visit.trainer.firstName} ${visit.trainer.lastName}` : "No Trainer"}</td>
                      <td className={TD_NORMAL}>{visit.organisation.name}</td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          onClick={() => handleTraineeVisitListModal(visit)}
                        >
                          {t('VISIT LIST')}
                        </Button>
                      </td>
                    </tr>
                    <Box marginY={1} />
                  </>
                )) : <tr><td colSpan={7} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
          />
        </>}



      {/* Trainee Visit List Modal */}
      {/* Visit List Tab */}
      {currentVisit &&
        <>
          <div className="flex justify-between my-4">
            <Typography variant="h5" fontWeight={'bold'} marginTop={1}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('VISIT_LIST')}: {groupStore.currentStudentProfile && groupStore.currentStudentProfile.firstName + " " + groupStore.currentStudentProfile.lastName}
            </Typography>
          </div>
          <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
            <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
            <Typography>Trainer & Assesor Not Check In Yet</Typography>
            <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check In By Trainer & Assesor</Typography>
            <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
            <Typography> Check Out By Trainer & Assesor</Typography>
          </Box>


          <VmTable
            loading={groupStore.loadingVisit}
            page={0}
            thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in " + t('STATUS')]}
            // tbody={studentStore.visitCurrentList.data.length > 0 ?
            //   studentStore.visitCurrentList.data.map((visit: any, index: number) => (
            tbody={
              groupStore.traineeshipVisitList &&
                groupStore.traineeshipVisitList.totalCount > 0 ?
                groupStore.traineeshipVisitList.data.map((visit: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                      <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "-"}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "-"}</td>
                      <td className={TD_NORMAL} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"} onClick={() => onToggleFold(visit.id)}>
                        {visit.organisationVisitDocuments.length > 0 ?
                          <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                          : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                      </td>
                      <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                        <span className={`${visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                          <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                        </span>
                      </td>
                    </tr>

                    {/* folder */}
                    <VmVisitSlideDownCard
                      visit={visit}
                      visitChangeList={userStore.visitChangeList.data}
                      loadingVisitTable={userStore.loadingVisit}
                      fold={fold}
                      currentTraineeship={currentVisit}
                      colspan={10}
                    />
                    <Box sx={{ marginY: 1 }} />
                    <Box marginY={1} />
                  </>
                )) : <tr><td colSpan={10} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
          />
        </>}


    </ContentLayout >
  )
});
export default OrganisationPage;
